import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";

import { Helmet } from "react-helmet-async";
import Footer from "components/Footers/Footer";

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const fetchedImages = [];
      for (let i = 9; i >= 1; i--) {
        const image = {
          original: require(`../assets/esayanPicture/gallery/gallery${i.toString().padStart(3, '0')}.jpeg`),
          thumbnail: require(`../assets/esayanPicture/gallery/gallery${i.toString().padStart(3, '0')}.jpeg`),
          originalHeight: "auto",
          originalWidth: "auto",
          thumbnailHeight:"auto",
          thumbnailWidth: "auto",
          loading:"eager",
          thumbnailLoading:"eager",
          originalAlt:"Esayan Bigbag",
          thumbnailAlt:"Esayan Bigbag",
          originalTitle:"Esayan Bigbag",
          thumbnailTitle:"Esayan Bigbag",

        };
        fetchedImages.push(image);
      }
      setImages(fetchedImages);
    };
    fetchImages();
  }, []);
  document.body.classList.add("sidebar-collapse");
  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin
  
    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector('.gt-current-lang img');
      const newImgSrc = 'https://cdn.gtranslate.net/flags/32/tr.png'; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement)
      imgElement.src = newImgSrc;
    }
  }, []);
  return (
    <> 
    <Helmet>
      <title>GALERİ | ESAYAN BIGBAG</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="GALERİ | ESAYAN BIGBAG"
        data-rh="true"
      />
      <link rel="canonical" href='/galeri' />
      <meta property="og:title" content="GALERİ | ESAYAN BIGBAG" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="GALERİ | ESAYAN BIGBAG" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.esayanbigbag.com.tr/galeri" />
    </Helmet>
    <WhiteNavbar navbarSelection={"gallery"} />
    
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div class="row">
      <div class="mr-auto ml-auto">
      <h1 style={{display:"none"}}>ESAYAN BIGBAG Gallery</h1>
       <h2 class="title d-flex">
          <div>
		        <div class="side2">ESAYAN&nbsp;</div>
		        <div class="side2">ESAYAN&nbsp;</div>
		       <div class="side2">ESAYAN&nbsp;</div>
	        </div>
        <div>
          <div class="side1">BIGBAG</div>
          <div class="side1">BIGBAG</div>
          <div class="side1">BIGBAG</div>
        </div>
      </h2></div></div>
    <ImageGallery items={images} showIndex={true}/>
    <Footer/>
     
      
    </>
  );
};

export default Gallery;
