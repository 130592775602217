import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import {RiTeamLine} from "react-icons/ri"
import { SiTrustpilot } from "react-icons/si";
import {MdOutlineComputer,MdLanguage} from "react-icons/md"
import Aos from "aos";

const NedenBiz = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div style={{backgroundColor:"white"}}>
      <Container  style={{ fontFamily: "Nucleo Outline" }} >
      {/* <div style={{ height:"5rem"}}></div> */}
        <div className="title">
          <h2
            className="title ml-auto mr-auto text-center"
            style={{ color: "#123274" }}
            data-aos="fade-right" data-aos-duration="1000"
          >
            NEDEN BİZİ SEÇMELİSİNİZ ?
          </h2>
          <br/>
        </div>
        <Row>
         <Col md="3">
            <div className="info info-hover" style={{cursor:"default"}}>
              <div className="icon icon-success icon-circle">
                <i className="now-ui-icons">
                    
                <SiTrustpilot />
                </i>
              </div>
              <h4 className="info-title" style={{ color: "#123274" }}>
                <b>Güvenilir Hizmet</b></h4>
              <p className="description" style={{fontSize:"15px"}}>
              Dünya standartlarına uygun fiyat aralıklarında kaliteli üretim.
              </p>
            </div>
          </Col>
          <Col md="3">
            <div className="info info-hover" style={{cursor:"default"}}>
              <div className="icon icon-info icon-circle">
                <i className="now-ui-icons">
                <RiTeamLine/>
                </i>
              </div>
              <h4 className="info-title" style={{ color: "#123274" }}><b>Müşteri Odaklı</b></h4>
              <p className="description" style={{fontSize:"15px"}}>
              İsteğe uygun şekilde ve zamanında üretim, koşulsuz müşteri memnuniyeti.
              </p>
            </div>
          </Col>
          <Col md="3">
            <div className="info info-hover" style={{cursor:"default"}}>
              <div className="icon icon-primary icon-circle">
                <i className="now-ui-icons">
                    <MdOutlineComputer/>
                </i>
              </div>
              <h4 className="info-title" style={{ color: "#123274" }}><b>Hızlı İletişim</b></h4>
              <p className="description" style={{fontSize:"15px"}}>
              Anında etkileşim, hızlı çözüm. Talepleriniz ve sorularınız için 7/24 canlı destek.
              </p>
            </div>
          </Col>
          <Col md="3">
            <div className="info info-hover" style={{cursor:"default"}}>
              <div className="icon icon-warning icon-circle">
                <i className="now-ui-icons">
                    <MdLanguage/>
                </i>
              </div>
              <h4 className="info-title" style={{ color: "#123274" }}><b>Uluslararası Ağ</b></h4>
              <p className="description" style={{fontSize:"15px"}}>
              Geniş ticari ağ ve sektöre yön veren şirketinize özel öncü çözümler.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <br/><br/>
    </div>
  );
};

export default NedenBiz;
