import React, { useEffect } from "react";
import Headers from "../components/headers/Headers";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar";
import Footer from "components/Footers/Footer";
import { Helmet } from "react-helmet-async";
import AnasayfaUrunler from "components/AnasayfaUrunler/AnasayfaUrunler";
import NedenBiz from "components/NedenBiz/NedenBiz";
import Yorumlar from "components/Yorumlar/Yorumlar";


const Home = () => {

  React.useEffect(() => {
    document.body.classList.add("sections-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    return function cleanup() {
      document.body.classList.remove("sections-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });


    useEffect(() => {
      // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
      const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin
    
      if (targetLink) {
        // Otomatik tıklama
        targetLink.click();
        const imgElement = document.querySelector('.gt-current-lang img');
        const newImgSrc = 'https://cdn.gtranslate.net/flags/32/tr.png'; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
        console.log(imgElement)
        imgElement.src = newImgSrc;
      }
    }, []);

  return (
    <>
      <Helmet>
        <title>
        GABYA INDUSTRY
        </title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Yeteneklerimizi keşfedin... Döküm, CNC ve Dövme çözümlerimizle hizmetinizdeyiz..."
          data-rh="true"
        />
        <link rel="canonical" href="/"/>
        <meta property="og:title" content="GABYA INDUSTRY" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Yeteneklerimizi keşfedin..." />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.gabyaindustry.com.tr/" />
      </Helmet>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        
        <Headers/>
        <AnasayfaUrunler/>
        <NedenBiz/>
       
        {/* <Yorumlar/> */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
