/*eslint-disable*/
import React, { useState } from "react";
import { Link, useHref } from "react-router-dom";
import logo from "../../assets/esayanPicture/esayanlogo.png";
import styles from "./Navbars.module.scss"
// reactstrap components
import {
  Button,
  Collapse,

  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
 
} from "reactstrap";

function WhiteNavbar({navbarSelection}) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
 
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg" style={{paddingTop:"0px",paddingBottom:"5px",fontSize:"1rem"}}>
      <Container fluid>
          <div className="navbar-translate">
          <NavbarBrand className={styles.shadowCustom1} to="/" tag={Link} id="navbar-brand" >
              <img  className={`${styles.logoBackground} ${styles.logo}`} src={logo} alt="logo" width={200} height={68} title="Esayan Bigbag" loading="eager" />
            </NavbarBrand>
           
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar className="sidebarBackground">
            <Nav className="m-auto" id="ceva" navbar style={{fontWeight:"bolder",fontSize:"1.3em"}}>
              <NavItem>
                <NavLink href="/">
                  ANA SAYFA
                </NavLink>
              </NavItem>
              <NavItem className={window.location.href.includes("hakkimizda") && "active"}>
                <NavLink href="/hakkimizda" >
                  HAKKIMIZDA
                </NavLink>
              </NavItem>
              <NavItem className={window.location.href.includes("urunlerimiz") && "active"}>
                <NavLink href="/urunlerimiz">
                  ÜRÜNLERİMİZ
                </NavLink>
              </NavItem>
              <NavItem className={window.location.href.includes("sertifikalar") && "active"}>
                <NavLink href="/sertifikalar">
                  SERTİFİKALAR
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink href="/bizden-haberler">
                  BİZDEN HABERLER
                </NavLink>
              </NavItem> */}
              <NavItem className={window.location.href.includes("galeri") && "active"}>
                <NavLink href="/galeri">
                  GALERİ
                </NavLink>
              </NavItem>
              <NavItem className={window.location.href.includes("iletisim") && "active"}>
                <NavLink href="/iletisim" >
                  İLETİŞİM
                </NavLink>
              </NavItem>
              
            </Nav>
            <Nav id="ceva2" navbar>
              <NavItem>
                <Button
                  className="nav-link btn-default"
                  color="info"
                  href={require("../../assets/esayanDocument/ESAYAN AMBALAJ CALATOGUE(Standart)_compressed.pdf")}
                  target="_blank"
                >
                  <p>E-KATALOG</p>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
