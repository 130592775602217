/*eslint-disable*/
import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Footer() {
  
  return (
    <>
      <footer className="footer" style={{ fontFamily: "Nucleo Outline" }}>
        <br/><hr/><br/>
        <Container >
          <div className="content text-center">
            <Row>
              {/* <Col md="2" className="text-md-left text-sm-center">
                <h5><b>Menü</b></h5>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="/"
                
                    >
                      Ana Sayfa
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/hakkimizda"
                     
                    >
                      Hakkımızda
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz"
                      
                    >
                      Ürünlerimiz
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/sertifikalar"
                      
                    >
                      Sertifikalar
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/galeri"
                      
                    >
                      Galeri
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/iletisim"
                      
                    >
                      İletişim
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md="2" className="text-md-left text-sm-center">
                <h5><b>Ürünlerimiz</b></h5>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#standartbigbag"
                      
                    >
                      Standart Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#crosscornerbigbag"
                     
                    >
                      Cross Corner Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#ventillibigbag"
                     
                    >
                      Ventilli Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#qbigbagid"
                      
                    >
                      Q Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#konteynerbigbag"
                      
                    >
                      Konteyner Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#oneloopbigbag"
                      
                    >
                      One Loop Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#slingbags"
                      
                    >
                      Sling Bags
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#iletkenbigbag"
                      
                    >
                      İletken Bigbag
                    </a>
                  </li>
                </ul>
              </Col> */}
              {/* <Col md="6" className="text-md-left text-sm-center">
                <h5><b>Adres</b></h5>
                <div className="social-feed">
                  <div className="feed-line">
                    
                    <p>
                    Demirciler Mah. Demirci Sok. No:60/2 Dilovası/Kocaeli
                    </p>
                  </div>
                  <div className="feed-line">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12075.986802615414!2d29.54298190366345!3d40.82803613763801!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb21b159fa6637%3A0xb2ff7106472f16bd!2sEsayan%20Grup!5e0!3m2!1sen!2str!4v1708198144037!5m2!1sen!2str" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    
                  </div>
                </div>
              </Col> */}
              <Col md="12" className="text-md-center text-sm-center">
                <h5><b>Bizi Takip Edin</b></h5>
                <ul className="social-buttons">
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="instagram"
                      target="_blank"
                      href="https://www.instagram.com/gabyaindustry/"
                      
                    >
                      <i className="fab fa-instagram"></i>
                      <span style={{display:"none"}}>Instagram GABYA INDUSTRY</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="facebook"
                      target="_blank"
                      href="https://www.instagram.com/gabyaindustry/"
                    >
                      <i className="fab fa-facebook-square"></i>
                      <span style={{display:"none"}}>Facebook GABYA INDUSTRY</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="linkedin"
                      href="https://www.instagram.com/gabyaindustry/"
                      rel="ESAYAN BIGBAG LinkedIn Account"
                      text="ESAYAN BIGBAG LinkedIn Account"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                      <span style={{display:"none"}}>LinkedIn GABYA INDUSTRY</span>
                    </Button>
                  </li>
                  
                </ul>
                <h5>
                  <small><b><u>E-mail</u></b></small>
                </h5>
                <h5>
                info@gabyaindustry.com
                </h5>
                <h5>
                  <small><b><u>Telefon</u></b></small>
                </h5>
                <h5>
                0 541 631 25 50
                </h5>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Powered by{" "}<a className="text-danger" href="/">Sisprime Digital Solutions</a>
            
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
