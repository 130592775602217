
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import Home from "./pages/Home";
import AboutUs from "pages/AboutUs";
import Gallery from "pages/Gallery";
import ContactUs from "pages/ContactUs";
// pages

import { HelmetProvider } from "react-helmet-async";

import Urunlerimiz from "pages/Urunlerimiz";
import Sertifikalarimiz from "pages/Sertifikalarimiz";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
 
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/hakkimizda" element={<AboutUs/>} />
          <Route path="/urunlerimiz" element={<Urunlerimiz/>} />
          <Route path="/sertifikalar" element={<Sertifikalarimiz/>} />
          {/* <Route path="/bizden-haberler" element={<AboutUs/>} /> */}
          <Route path="/galeri" element={<Gallery/>} />
          <Route path="/iletisim" element={<ContactUs/>} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
 
 
);
