import Aos from "aos";
import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { HashLink } from "react-router-hash-link";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import dokum from "../../assets/gabyaPicture/dokum.mp4";
import crosscornerbigbag from "../../assets/esayanPicture/crosscornerbigbag.png";
import vetillibigbag from "../../assets/esayanPicture/vetillibigbag.png";
import qbigbag from "../../assets/esayanPicture/qbigbag.png";
import konteynerbigbag from "../../assets/esayanPicture/konteynerbigbag.png";
import oneloopbigbag from "../../assets/esayanPicture/oneloopbigbag.png";
import slingbags from "../../assets/esayanPicture/slingbags.png";
import standartcuval from "../../assets/esayanPicture/standartcuval.png";
import iletkenbigbag from "../../assets/esayanPicture/iletkenbigbag.png";
import { BiSend } from "react-icons/bi";

const AnasayfaUrunler = () => {
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      {/* <Helmet>
        <title>
          Master Drivers Blog -  Expert Driving Tips & Valuable Information
        </title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Explore Master Drivers Blog for expert driving tips and valuable information. Enhance your skills and stay informed on the road. Discover essential content now"
          data-rh="true"
        />
        <link rel="canonical" to="/blog" />
        <meta
          property="og:title"
          content="Master Drivers Blog -  Expert Driving Tips & Valuable Information"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Explore Master Drivers Blog for expert driving tips and valuable information. Enhance your skills and stay informed on the road. Discover essential content now"
        />
        <meta
          property="og:image"
          content=""
        />
        <meta
          property="og:url"
          content="https://www.masterdrivers.ca/blog"
        />
      </Helmet> */}
      <div style={{ fontFamily: "Nucleo Outline" }}>
        <Container style={{ overflow: "hidden", fontFamily: "Nucleo Outline" }}>
          <Row>
            <Col
              className="ml-auto mr-auto text-center"
              data-aos="fade-down"
              data-aos-duration="1000"
              md="8"
            >
              <h2 className="title">Yeteneklerimizi keşfedin</h2>
              <h3 style={{ display: "none" }}>
                Geniş üretim yelpazesi ile tüm döküm, cnc imalat ve dövme
                ihtiyaçlarınıza en uygun çözümler ile hizmetinizdeyiz.
              </h3>
              <h4 className="description">
                Geniş üretim yelpazesi ile tüm döküm, cnc imalat ve dövme
                ihtiyaçlarınıza en uygun çözümler ile hizmetinizdeyiz.
              </h4>
            </Col>
          </Row>
          <div
            id="standartbigbag"
            className="section-space"
            style={{ height: "6rem" }}
          ></div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h3 className="title">&#8764; Döküm &#8764;</h3>
            </Col>
            <Col className="ml-auto" style={{ alignSelf: "center" }} md="6">
              <video width="400" height="auto" autoPlay loop muted>
                <source
                  src={require("assets/gabyaPicture/dokum2.mp4")}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Col>
            <Col
              className="mr-auto"
              md="6"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="info info-horizontal" data-aos-offset="100">
                <div className="icon icon-info">
                  <i className="now-ui-icons">
                    <BiSend />
                  </i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Döküm nedir ?</b>
                  </h4>
                  <p className="description">
                    Döküm, genellikle metal veya başka malzemelerin eritilip bir
                    kalıba dökülerek istenilen şekli almasını sağlayan bir
                    üretim sürecidir. Bu süreçte, önce malzeme yüksek sıcaklıkta
                    eritilir, ardından özel tasarlanmış kalıplara dökülerek
                    soğutulur. Soğuduktan sonra, kalıptan çıkarılan parça,
                    istenen nihai ürünü oluşturur.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons">
                    <BiSend />
                  </i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Döküm Avantajları:</b>
                  </h4>
                  <p className="description">
                    Dökümün avantajları arasında karmaşık geometrili parçaların
                    üretiminde esneklik, maliyet etkinliği, seri üretim imkanı
                    ve geniş malzeme seçenekleri bulunmaktadır. Bu nedenle,
                    döküm, birçok endüstriyel uygulamada tercih edilen bir
                    üretim yöntemidir.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons">
                    <BiSend />
                  </i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Döküm Çeşitleri:</b>
                  </h4>
                  <p className="description">
                    Kum Dökümü (Sand Casting) Yatay Döküm (Horizontal Casting)
                    Basınçlı Döküm (Pressure Die Casting) Kayıp Köpük Dökümü
                    (Lost Foam Casting) Seramik Döküm (Investment Casting)
                    Manyetik Döküm (Electromagnetic Casting) Yoğunluk Dökümü
                    (Centrifugal Casting)
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <div
            id="crosscornerbigbag"
            className="section-space"
            style={{ height: "6rem" }}
          ></div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h3 className="title">&#8764; CNC &#8764;</h3>
            </Col>
            <Col
              className="ml-auto mt-5 mt-md-0 order-3 order-md-2"
              data-aos="fade-right"
              data-aos-duration="3000"
              md="6"
            >
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons ">
                    <BiSend />
                  </i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>CNC Nedir ?</b>
                  </h4>
                  <p className="description">
                  CNC, bir bilgisayar tarafından kontrol edilen ve önceden belirlenmiş bir dizi komutu izleyen makineleri ifade eder. Bu makineler genellikle metal, ahşap, plastik ve diğer malzemeleri kesme, delme, frezeleme ve diğer işlemlerde kullanılır.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons">
                    <BiSend />
                  </i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Avantajları:</b>
                  </h4>
                  <p className="description">
                  Hassasiyet, Yüksek Üretkenlik, Çeşitli Malzemelerde Kullanım, Tekrarlanabilirlik, Karmaşık Tasarımları İşleme Yeteneği
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons">
                    <BiSend />
                  </i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>CNC Makine Çeşitleri</b>
                  </h4>
                  <p className="description">
                  CNC Freze Makineleri - CNC Torna Makineleri - CNC Lazer Kesim Makineleri - CNC Su Jeti Kesim Makineleri
                  </p>
                </div>
              </div>
            </Col>
            <Col
              className="ml-auto order-2 order-md-3"
              md="6"
              style={{ alignSelf: "center" }}
            >
             <video width="400" height="auto" autoPlay loop muted>
                <source
                  src={require("assets/gabyaPicture/cnc.mp4")}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Col>
          </Row>
          <div
            id="ventillibigbag"
            className="section-space"
            style={{ height: "6rem" }}
          ></div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h3 className="title">&#8764; Metal Dövme &#8764;</h3>
            </Col>
            <Col
              className="ml-auto"
              
              md="6"
            >
              <video width="400" height="auto" autoPlay loop muted>
                <source
                  src={require("assets/gabyaPicture/dovme.mp4")}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Col>
            <Col
              className="mr-auto"
              data-aos="fade-left"
              data-aos-duration="3000"
              md="5"
            >
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons">
                    <BiSend />
                  </i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Metal dövme nedir ?</b>
                  </h4>
                  <p className="description">
                  Metal dövme, bir metal parçanın ısıtılıp özel kalıplar içinde şekillendirilmesi işlemidir. Genellikle çekiç veya pres gibi araçlar kullanılarak metal, özel tasarlanmış kalıplara yerleştirilir ve şekil verilir. Bu süreç sırasında metalin iç yapısı daha homojen ve dayanıklı hale gelir.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons">
                    <BiSend />
                  </i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Avantajları:</b>
                  </h4>
                  <p className="description">
                  Dayanıklılık - Mukavemet - Hassasiyet ve Kalite - Malzeme Verimliliği - Çeşitli Malzemelerle Uyum - Karmaşık Tasarımların İşlenmesi - İyi Tane Yapısı - Yüksek Sıcaklık Dayanımı
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons">
                    <BiSend />
                  </i>
                </div>
                <div className="description">
                  <h4 className="info-title">
                    <b>Metal dövme çeşitleri:</b>
                  </h4>
                  <p className="description">
                  Açık Dövme, Kapalı Dövme, Makine Dövme, Dövme Makarası, Pres Dövme, Yumuşak Dövme ve Soğuk Dövme olmak üzere metal dövme çeşitleri farklı uygulama ve gereksinimlere yönelik çeşitli şekillendirme yöntemlerini kapsar.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AnasayfaUrunler;
