import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navbars.module.scss"
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  NavLink,
} from "reactstrap";
import logo from "../../assets/gabyaPicture/gabyaindustrylogo.png";

function ScrollTransparentNavbar() {
  let scrollSize = 640;
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > scrollSize || document.body.scrollTop) > scrollSize
      ? ""
      : " navbar-transparent"
  );
  const [buyButtonColor, setBuyButtonColor] = React.useState(
    (document.documentElement.scrollTop > scrollSize || document.body.scrollTop) > scrollSize
      ? "info"
      : "neutral"
  );

  const [logoStyle, setLogoStyle] = React.useState(true);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > scrollSize ||
        document.body.scrollTop > scrollSize
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
        setLogoStyle(false)
      } else if (
        document.documentElement.scrollTop < scrollSize ||
        document.body.scrollTop < scrollSize
      ) {
        setNavbarColor(" navbar-transparent");
        setBuyButtonColor("neutral");
        setLogoStyle(true)
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} color="white" expand="lg" style={{paddingTop:"0px",paddingBottom:"5px",fontSize:"1rem"}}>
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand className={styles.shadowCustom1} to="/" tag={Link} id="navbar-brand" >
              <img  className={logoStyle ? `${styles.logoBackground} ${styles.logo}` : `${styles.logo}`} src={logo} alt="logo" width={200} height={80} title="ESAYAN BIGBAG" loading="eager" />
            </NavbarBrand>
            {/* <UncontrolledTooltip target="navbar-brand">
              Designed by Invision. Coded by Creative Tim
            </UncontrolledTooltip> */}
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar fluid className="sidebarBackground">
            <Nav className="m-auto" id="ceva" navbar style={{fontWeight:"bolder",fontSize:"1.3em"}}>
              {/* <NavItem className="active" >
                <NavLink href="/">
                  ANA SAYFA
                </NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink href="/hakkimizda" >
                  HAKKIMIZDA
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/urunlerimiz">
                  ÜRÜNLERİMİZ
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/sertifikalar">
                  SERTİFİKALAR
                </NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink href="/bizden-haberler">
                  BİZDEN HABERLER
                </NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink href="/galeri">
                  GALERİ
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/iletisim">
                  İLETİŞİM
                </NavLink>
              </NavItem> */}
              
            </Nav>
            {/* <Nav id="ceva2" navbar>
              <NavItem>
                <Button
                  className="nav-link btn-default"
                  color={buyButtonColor}
                  href={require("../../assets/esayanDocument/ESAYAN AMBALAJ CALATOGUE(Standart)_compressed.pdf")}
                  target="_blank"
                >
                  <p>E-KATALOG</p>
                </Button>
              </NavItem>
            </Nav> */}
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ScrollTransparentNavbar;
