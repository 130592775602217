import React, { useEffect, useState } from "react";
import axios from "axios";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';


// core components
import WhiteNavbar from "components/Navbars/WhiteNavbar";
import { Helmet } from "react-helmet-async";
import { BiCheck } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineMail, AiTwotonePhone } from "react-icons/ai";
import Footer from "components/Footers/Footer";

function ContactUs() {
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [numberFocus, setNumberFocus] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state?.data || {};
  console.log(receivedData)

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: (location.state?.data ? `I want to get a special offer for ${receivedData?.id} number ${receivedData?.name} ${receivedData?.type}. Please contact me.(You can add extra information)` : ""),
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post("https://adminemail.pythonanywhere.com/api/contact-esayan/", formData);
      // Optionally reset the form data
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      navigate("/contact-complete");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (show an error message)
    }
  };

  React.useEffect(() => {
    document.body.classList.add("contact-page");
    // document.body.classList.add("sidebar-collapse");
    // document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin
  
    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector('.gt-current-lang img');
      const newImgSrc = 'https://cdn.gtranslate.net/flags/32/tr.png'; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement)
      imgElement.src = newImgSrc;
    }
  }, []);
  return (
    <>
    <Helmet>
      <title>İLETİŞİM | ESAYAN BİGBAG</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Ürünlerimizle ve şirketimizle alakalı her konuda bizimle iletişime geçebilirsiniz."
        data-rh="true"
      />
      <link rel="canonical" href='/iletisim' />
      <meta property="og:title" content="İLETİŞİM | ESAYAN BİGBAG" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Ürünlerimizle ve şirketimizle alakalı her konuda bizimle iletişime geçebilirsiniz." />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.esayanbigbag.com.tr/iletisim" />
    </Helmet>
    <WhiteNavbar navbarSelection={"contact-us"}/>
      <div className="wrapper" style={{ fontFamily: "Nucleo Outline" }}>
        <br/><br/>
        <div className="main">
          <div className="contact-content">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                <h1 style={{display:"none"}}>Esayan BIGBAG Contact Form</h1>
                  <h2 className="title">Bizimle iletişime geçin</h2>
                  <h3 style={{display:"none"}}>Esayan BIGBAG, Get in Touch</h3>
                  <p className="description">
                    Ürünlerimizle ve şirketimizle alakalı her konuda bizimle iletişime geçebilirsiniz. Size en kısa zamanda dönüş yapacağımızdan emin olabilirsiniz. <br></br>
                    <br></br>
                  </p>
                  <Form id="contact-form" onSubmit={handleSubmit}>
                    <label>İsim</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons">
                          <BsFillPersonFill />
                          </i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="name"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleInputChange}
                        aria-label="Your Name..."
                        autoComplete="name"
                        placeholder="İsminizi giriniz"
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Email</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons">
                          <AiOutlineMail />
                          </i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleInputChange}
                        aria-label="Email Here..."
                        autoComplete="email"
                        placeholder="Email Adresinizi Giriniz"
                        type="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Telefon</label>
                    <InputGroup
                      className={numberFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons">
                          <AiTwotonePhone />
                          </i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="phone"
                        name="phone"
                        required
                        value={formData.phone}
                        onChange={handleInputChange}
                        autoComplete="number"
                        placeholder="Numaranızı giriniz"
                        type="text"
                        onFocus={() => setNumberFocus(true)}
                        onBlur={() => setNumberFocus(false)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label for="message">Mesajınız</label>
                      <Input
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        rows="6"
                        type="textarea"
                        placeholder="Mesajınızı Giriniz"
                      ></Input>
                    </FormGroup>
                    <div className="submit text-center">
                      <Button
                        className="btn-raised btn-round"
                        color="info"
                        defaultValue="Contact Us"
                        type="submit"
                      >
                        Gönder
                      </Button>
                    </div>
                  </Form>
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <div className="info info-horizontal mt-5">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                      <BiCheck size={40}/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Adresimiz</h4>
                      <p>
                      Demirciler Mah. Demirci Sok. No:60/2 Dilovası/Kocaeli
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                        <BiCheck size={40}/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Telefon Numaralarımız</h4>
                      <p>
                      0 262 644 04 25<br></br>
                      0 543 809 10 24
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="business_briefcase-24">
                      <BiCheck size={40}/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Email Adreslerimiz</h4>
                      <p>
                      info@esayanambalaj.com.tr<br></br>
                      export@esayanbigbag.com.tr
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer/>
    
      </div>
    </>
  );
}

export default ContactUs;
