import Aos from 'aos';
import Footer from 'components/Footers/Footer';
import WhiteNavbar from 'components/Navbars/WhiteNavbar';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import {  Card, CardBody,  CardTitle, Col, Container, Row } from 'reactstrap'

const Sertifikalarimiz = () => {
    React.useEffect(() => {
        Aos.init();
    }, []);
    useEffect(() => {
        // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
        const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin
      
        if (targetLink) {
          // Otomatik tıklama
          targetLink.click();
          const imgElement = document.querySelector('.gt-current-lang img');
          const newImgSrc = 'https://cdn.gtranslate.net/flags/32/tr.png'; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
          console.log(imgElement)
          imgElement.src = newImgSrc;
        }
      }, []);
    return (
        <>
          <Helmet>
      <title>SERTİFİKALARIMIZ | ESAYAN BIGBAG</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="SERTİFİKALARIMIZ | ESAYAN BIGBAG"
        data-rh="true"
      />
      <link rel="canonical" href='/about-us' />
      <meta property="og:title" content="SERTİFİKALARIMIZ | ESAYAN BIGBAG" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="SERTİFİKALARIMIZ | ESAYAN BIGBAG" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.esayanbigbag.com.tr/sertifikalarimiz" />
    </Helmet>
        <WhiteNavbar />
        <div className="team-2 pb-0" style={{ fontFamily: "Nucleo Outline" }} >          
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <div className="title">
                            <h2
                                className="title ml-auto mr-auto text-center"
                                style={{ color: "#123274" }}
                            >
                                SERTİFİKALARIMIZ
                            </h2>
                        </div>
                    </Col>
                </Row>
                <Row data-aos="fade-up"
                    data-aos-duration="1000">
                    <Col md="4">
                        <Card className="card-profile card-plain">
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="img img-raised rounded"
                                        style={{ border: "1px solid black" }}
                                        src={require("assets/esayanDocument/13H2Y UN BELGESİ-05.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>13H2Y UN BELGESİ</b></CardTitle>


                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-profile card-plain">
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="img img-raised rounded"
                                        style={{ border: "1px solid black" }}
                                        src={require("assets/esayanDocument/13H3Y UN BELGESİ-05.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>13H3Y UN BELGESİ</b></CardTitle>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-profile card-plain">
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        style={{ border: "1px solid black" }}
                                        className="img img-raised rounded"
                                        src={require("assets/esayanDocument/GIMDES SERTİFİKA-1.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>GIMDES SERTİFİKASI</b></CardTitle>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row data-aos="fade-up"
                    data-aos-duration="1000">
                    <Col md="4">
                        <Card className="card-profile card-plain" style={{ marginTop: "0px" }}>
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="img img-raised rounded"
                                        style={{ border: "1px solid black" }}
                                        src={require("assets/esayanDocument/ISO 14001-1.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>ISO 14001 SERTİFİKASI</b></CardTitle>


                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-profile card-plain" style={{ marginTop: "0px" }}>
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="img img-raised rounded"
                                        style={{ border: "1px solid black" }}
                                        src={require("assets/esayanDocument/ISO 22000-1.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>ISO 22000 SERTİFİKASI</b></CardTitle>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-profile card-plain" style={{ marginTop: "0px" }}>
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        style={{ border: "1px solid black" }}
                                        className="img img-raised rounded"
                                        src={require("assets/esayanDocument/ISO 45001-1.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>ISO 45001 SERTİFİKASI</b></CardTitle>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row data-aos="fade-up"
                    data-aos-duration="1000">
                    <Col md="4">
                        <Card className="card-profile card-plain" style={{ margin: "0px" }}>
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="img img-raised rounded"
                                        style={{ border: "1px solid black" }}
                                        src={require("assets/esayanDocument/ISO 9001-1.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>ISO 9001 SERTİFİKASI</b></CardTitle>


                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-profile card-plain" style={{ margin: "0px" }}>
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="img img-raised rounded"
                                        style={{ border: "1px solid black" }}
                                        src={require("assets/esayanDocument/KOSHER SERTİFİKA-1.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>KOSHER SERTİFİKASI</b></CardTitle>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-profile card-plain" style={{ margin: "0px" }}>
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        style={{ border: "1px solid black" }}
                                        className="img img-raised rounded"
                                        src={require("assets/esayanDocument/MİGRASYON TESTİ ESAYAN 2023-04.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>MİGRASYON TESTİ 2023</b></CardTitle>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row data-aos="fade-up"
                    data-aos-duration="1000">
                    <Col md="4">
                        <Card className="card-profile card-plain" style={{ margin: "0px" }}>
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="img img-raised rounded"
                                        style={{ border: "1px solid black" }}
                                        src={require("assets/esayanDocument/STEVEDOR BELGESİ-1.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>STEVEDOR BELGESİ</b></CardTitle>


                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-profile card-plain" style={{ margin: "0px" }}>
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="img img-raised rounded"
                                        style={{ border: "1px solid black" }}
                                        src={require("assets/esayanDocument/TYP-C SERTİFİKA-1.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>TYPE-C SERTİFİKASI</b></CardTitle>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-profile card-plain" style={{ margin: "0px" }}>
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        style={{ border: "1px solid black" }}
                                        className="img img-raised rounded"
                                        src={require("assets/esayanDocument/İŞLETME KAYIT BELGESİ-1.png")}
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <CardTitle tag="h4" style={{ color: "#123274" }}><b>İŞLETME KAYIT BELGESİ</b></CardTitle>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
        </div>
        <Footer />
        </>

    )
}

export default Sertifikalarimiz